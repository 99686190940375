import { Transaction } from "@mysten/sui/transactions";
import { useWallet } from "@suiet/wallet-kit";
import { Form, Input, message, Modal, Select } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { GiPayMoney } from "react-icons/gi";

const AdminDepositReward = ({ CoinObjectId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const [days, setDays] = useState("");
  const [selectedToken, setSelectedToken] = useState("SUTOKEN");
  const wallet = useWallet();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const fetchRewardDeposited = async () => {
    try {
      const response = await axios.post("https://rpc.testnet.sui.io/", {
        jsonrpc: "2.0",
        id: 1,
        method: "sui_getObject",
        params: [
          "0xa606b43e244bdc268a60a401e6876239f80739fd1d0318ea419865ae3695274e",
          {
            showType: true,
            showOwner: true,
            showPreviousTransaction: true,
            showDisplay: false,
            showContent: true,
            showBcs: false,
            showStorageRebate: true,
          },
        ],
      });
      console.log(
        response?.data?.result?.data?.content?.fields || 0,
        "0xa606b43e244bdc268a60a401e6876239f80739fd1d0318ea419865ae3695274e"
      ); // time stamp 1730960933004
      return response?.data || [];
    } catch (error) {
      console.error("Error fetching object:", error);
      return "0.00";
    }
  };

  // deposit contract
  const handleDeposit = async () => {
    // if (selectedToken.coinObjectId?.length > 0) {
    //     let baseObj = selectedToken.coinObjectId[0].coinObjectId;

    //     for (let i = 1; i < selectedToken.coinObjectId.length; i++) {
    //       txb.mergeCoins(baseObj, [selectedToken.coinObjectId[i].coinObjectId]);
    //     }
    //   }
    //   if (selectedToken.coinObjectId.length > 0) {
    //     const [coinObject] = txb.splitCoins(
    //       txb.object(selectedToken.coinObjectId[0].coinObjectId),
    //       [amountInNanoUnits]
    //     );
    if (!amount || isNaN(amount) || Number(amount) <= 0) {
      message.error("Please enter a valid amount to stake.");
      return;
    }
    try {
      const txb = new Transaction();
      const FairmilePackage =
        process.env.REACT_APP_ENV === "DEV"
          ? process.env.REACT_APP_DEV_PACKAGE_ID
          : process.env.REACT_APP_PROD_PACKAGE_ID;
      const FairmileModule = "teststaking";
      const CoinObjectId =
        "0xa856e23e08eec50b2819530adc0851fd3cad7ffc2a19157307a33a1fe073e7f2";
      const SUTOKENCoinType =
        process.env.REACT_APP_ENV === "DEV"
          ? process.env.REACT_APP_DEV_COIN
          : process.env.REACT_APP_PROD_COIN;
      const SUICoinType = "0x2::sui::SUI";
      const coinType =
        selectedToken === "SUTOKEN" ? SUTOKENCoinType : SUICoinType;

      txb.moveCall({
        target: `${FairmilePackage}::${FairmileModule}::depositRewards`,
        arguments: [
          txb.object(CoinObjectId),
          txb.pure.u64(days),
          txb.object("0x06"),
        ],
        typeArguments: [coinType],
      });
      const result = await wallet.signAndExecuteTransactionBlock({
        transactionBlock: txb,
      });
      message.success("Tokens staked successfully!");
      console.log("Transaction result:", result);
    } catch (error) {
      message.error("Staking failed. Please try again.");
      console.error("Staking error:", error);
    }
  };

  return (
    <>
      <GiPayMoney onClick={showModal} className="fs-5" />
      <Modal
        title={<h2 className="modal-title">Deposit Reward</h2>}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className="deposit-reward-modal"
      >
        <div className="deposit-form-content">
          <Form layout="vertical">
            <Form.Item label="Select Token" required>
              <Select
                value={selectedToken}
                onChange={(value) => setSelectedToken(value)}
                options={[
                  { label: "SUI", value: "SUI" },
                  { label: "SUTOKEN", value: "SUTOKEN" },
                ]}
                className="input-field"
              />
            </Form.Item>
            <Form.Item label="Deposit Duration (Days)" required>
              <Input
                value={days}
                onChange={(e) => setDays(e.target.value)}
                placeholder="Enter days..."
                type="number"
                min="1"
                className="input-field"
              />
            </Form.Item>
            <Form.Item label="Deposit Reward Amount" required>
              <Input
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder="Enter deposit reward amount..."
                type="number"
                min="0"
                className="input-field"
              />
            </Form.Item>
            <Form.Item className="text-center">
              <Button
                type="primary"
                className="submit-button"
                size="large"
                onClick={handleDeposit}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default AdminDepositReward;
