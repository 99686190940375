import React, { useState } from "react";
import { Form, Input, Button, Card, Row, Col, message, Modal } from "antd";
import { useWallet } from "@suiet/wallet-kit";
import { Transaction } from "@mysten/sui/transactions";

const Stake = () => {
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const wallet = useWallet();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleStake = async () => {
    if (!amount || isNaN(amount) || Number(amount) <= 0) {
      message.error("Please enter a valid amount to stake.");
      return;
    }
    try {
      setLoading(true);
      const txb = new Transaction();
      const amountInNanoUnits = Number(amount) * 1e9;
      const FairmilePackage =
        process.env.REACT_APP_ENV === "DEV"
          ? process.env.REACT_APP_DEV_PACKAGE_ID
          : process.env.REACT_APP_PROD_PACKAGE_ID;
      const FairmileModule = "teststaking";
      const CoinObjectId =
        "0x68d506a3887ebbb7021f4811e81f399acf35dce573bde31e63d673b4f46dc4a5";
        const CoinType =
        process.env.REACT_APP_ENV === "DEV"
          ? process.env.REACT_APP_DEV_COIN
          : process.env.REACT_APP_PROD_COIN;
      const Counter =
        process.env?.REACT_APP_MODE === "DEV"
          ? process.env?.REACT_APP_DEV_STAKE_COUNTER
          : process.env?.REACT_APP_PROD_STAKE_COUNTER;
      txb.moveCall({
        target: `${FairmilePackage}::${FairmileModule}::stake`,
        arguments: [
          txb.object("0x06"),
          txb.object(CoinObjectId),
          txb.object(Counter),
        ],
        typeArguments: [CoinType],
      });
      const result = await wallet.signAndExecuteTransactionBlock({
        transactionBlock: txb,
      });
      message.success("Tokens staked successfully!");
      console.log("Transaction result:", result);
    } catch (error) {
      message.error("Staking failed. Please try again.");
      console.error("Staking error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <span onClick={showModal} style={{ cursor: "pointer" }}>
        Stake
      </span>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        centered
        title={null}
      >
        <div className="modal-content d-flex align-items-center justify-content-center p-4">
          <h2 className="text-center mb-4">Stake Your Tokens</h2>
          <Form layout="vertical" className="w-100">
            <Form.Item label="Amount to Stake" className="font-weight-bold">
              <Input
                placeholder="Enter amount"
                size="large"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </Form.Item>
            <Form.Item className="mt-4">
              <Button
                type="primary"
                size="large"
                className="w-100"
                onClick={handleStake}
                loading={loading}
                disabled={!amount}
              >
                {loading ? "Staking..." : "Stake"}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default Stake;
