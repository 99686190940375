import { useWallet } from "@suiet/wallet-kit";
import { Card } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import moment from "moment";
import { ApolloClient, gql, InMemoryCache, useQuery } from "@apollo/client";
import { Copy } from "../../Components/Copy/Copy";
import { SuiGraphql } from "../../Components/Common/Apis/Apis";

const client = new ApolloClient({
  uri: SuiGraphql,
  cache: new InMemoryCache(),
});

const TRANSACTION_QUERY = gql`
  query ($objectID: SuiAddress!, $senderAddress: SuiAddress!) {
    transactionBlocks(
      filter: { changedObject: $objectID, sentAddress: $senderAddress }
    ) {
      nodes {
        digest
        sender {
          address
        }
        effects {
          balanceChanges {
            nodes {
              owner {
                address
              }
              amount
            }
          }
        }
      }
    }
  }
`;
const AllStake = () => {
  const [stakeTransactin, SetStakeTransactin] = useState([]);
  const stakeCounter =
    process.env.REACT_APP_ENV === "DEV"
      ? process.env.REACT_APP_DEV_STAKE_COUNTER
      : process.env.REACT_APP_PROD_STAKE_COUNTER;
  const wallet = useWallet();
  const walletAddress = wallet.address;

  const {
    loading: queryLoading,
    error,
    data,
  } = useQuery(TRANSACTION_QUERY, {
    variables: {
      objectID: stakeCounter,
      senderAddress: walletAddress,
    },
    client,
  });

  useEffect(() => {
    if (!queryLoading && data) {
      SetStakeTransactin(data.transactionBlocks.nodes);
    }
  }, [queryLoading, data]);

  const formatAmount = (amount) => {
    const positiveAmount = Math.abs(amount || 0);
    return (positiveAmount / 1e9).toFixed(2) + " SUTOKEN";
  };
  const formatAddress = (address) =>
    address ? `${address.slice(0, 5)}...${address.slice(-3)}` : "";
  const formatHash = (hash) =>
    hash ? `${hash.slice(0, 5)}...${hash.slice(-3)}` : "";

  return (
    <div className="">
      {stakeTransactin && stakeTransactin.length > 0 ? (
        <Table hover responsive>
          <thead>
            <tr className="text-start">
              <th>Stake Amount</th>
              <th>Sender</th>
              <th>Stake Time</th>
              <th>Unstake Time</th>
              <th>Transaction Hash</th>
            </tr>
          </thead>
          <tbody>
            {stakeTransactin.map((transaction, index) => (
              <tr key={index} className="text-success">
                <td>
                  {formatAmount(
                    transaction?.effects?.balanceChanges?.nodes[1]?.amount
                  )}
                </td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td>
                  {formatHash(transaction.digest)}{" "}
                  <Copy content={transaction.digest} />
                </td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p className="text-muted">No transactions found for this wallet.</p>
      )}
    </div>
  );
};

export default AllStake;
