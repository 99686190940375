export const RpcUrlSui_getObject = "https://rpc.mainnet.sui.io/";
export const getIndexAssetData ="https://api-defi.naviprotocol.io/getIndexAssetData";
export const Suivision = "https://suivision.xyz/account/";
export const Suiscan = "https://suiscan.xyz/mainnet/tx/";
export const SuivisionDefi = "https://edge.suivision.xyz/defi/";
export const exchangeInfo = "https://dapi.api.sui-prod.bluefin.io/exchangeInfo";
export const marketData = "https://dapi.api.sui-prod.bluefin.io/marketData";
export const apiToken = JSON.parse(sessionStorage.getItem("UserAuth"));
export const SuiGraphql = "https://sui-devnet.mystenlabs.com/graphql"

const Owner = process.env.REACT_APP_MODE;
export const BaseUrl = Owner === "DEV" ? process.env.REACT_APP_BACKEND_APP_DEV_BASE_URL : process.env.REACT_APP_BACKEND_APP_PROD_BASE_URL;