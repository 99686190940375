import { Card, Col, Row, DatePicker, Pagination, Select } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Badge, Table } from "react-bootstrap";
import { BaseUrl } from "../../Common/Apis/Apis";
import { IoMdArrowRoundBack, IoMdCopy } from "react-icons/io";
import toast from "react-hot-toast";
import moment from "moment";
import Swal from "sweetalert2";
import { Option } from "antd/es/mentions";
import WithdralRequestModal from "../../Modal/WithdralRequest/WithdralRequestModal";

const DepositTransaction = () => {
  const [withdrawalData, setWithdrawalData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [status, setStatus] = useState("");
  const [dateRange, setDateRange] = useState([]);

  const fetchWithdrawalData = async (
    pageNumber = 1,
    pageSize = 10,
    status = "",
    startDate = "",
    endDate = "",
  ) => {
    const apiToken = sessionStorage?.getItem("token");
    try {
      const response = await axios.get(`${BaseUrl}admin/getDepostTransaction`, {
        headers: {
          accept: "application/json",
          Authorization: apiToken.replace(/^"|"$/g, ""),
        },
        params: {
          pageNumber,
          pageSize,
          status,
          startDate,
          endDate,
        },
      });
      setWithdrawalData(response.data?.data?.transactions || []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching withdrawal data:", error);
      setLoading(false);
    }
  };

  const fetchTransactionReject = async (id, Transactionstatus) => {
    const apiToken = sessionStorage?.getItem("token");
    try {
      const response = await axios.put(
        `${BaseUrl}admin/updateDepositStatus`,
        {
          status: Transactionstatus,
          id: id,
        },
        {
          headers: {
            accept: "application/json",
            Authorization: apiToken.replace(/^"|"$/g, ""),
          },
        }
      );
      setLoading(false);
      toast.success("Transaction rejected successfully");
      fetchWithdrawalData(
        pageNumber,
        pageSize,
        status,
        dateRange[0],
        dateRange[1]
      );
    } catch (error) {
      console.error("Error rejecting transaction:", error);
      setLoading(false);
    }
  };

  const handleRejectTransaction = (id, Transactionstatus) => {
    Swal.fire({
        title: `${Transactionstatus=== "APPROVED"? "Accept" : "Reject"}  Transaction`,
        html: "<p>Are you sure you want to approve this transaction?</p>",
      showCancelButton: true,
      confirmButtonText:`${Transactionstatus === "APPROVED"? "Accept" : "Reject"}`,
      cancelButtonText: "Cancel",
      preConfirm: (reason) => {
        if (!reason) {
          Swal.showValidationMessage("You need to enter a reason");
        } else {
          return reason;
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        fetchWithdrawalData();
        fetchTransactionReject(id,Transactionstatus);
      }
    });
  };

  const handlePageChange = (page, pageSize) => {
    setPageNumber(page);
    setPageSize(pageSize);
    fetchWithdrawalData(page, pageSize, status, dateRange[0], dateRange[1]);
  };

  const handleStatusChange = (value) => {
    const statusValue = value === "ALL" ? "" : value;
    setStatus(statusValue);
    fetchWithdrawalData(
      pageNumber,
      pageSize,
      statusValue,
      dateRange[0],
      dateRange[1]
    );
  };

  const handleDateChange = (dates, dateStrings) => {
    setDateRange(dateStrings);
    fetchWithdrawalData(
      pageNumber,
      pageSize,
      status,
      dateStrings[0],
      dateStrings[1]
    );
  };

  const formatAddress = (address) => {
    return `${address?.slice(0, 6)}...${address?.slice(-4)}`;
  };

  const copyToClipboard = (address) => {
    navigator.clipboard.writeText(address);
    toast.success("Copied");
  };

  useEffect(() => {
    fetchWithdrawalData(
      pageNumber,
      pageSize,
      status,
      dateRange[0],
      dateRange[1]
    );
  }, [pageNumber, pageSize, status, dateRange]);

  return (
    <div className="container-fluid mt-5 min-vh-100">
      <Row gutter={[16, 16]} justify={"center"}>
        <Col xs={24} md={22} lg={22}>
          <Card
            title={
              <>
                <div className="d-flex flex-column flex-md-row">
                  <div>
                    <a
                      href="javascript: history.go(-1)"
                      className="text-decoration-none me-2"
                    >
                      <IoMdArrowRoundBack />
                    </a>
                    <span> Deposit Transactions</span>
                  </div>
                  <div className="ms-auto mt-3  mt-md-0 d-flex align-items-baseline flex-column flex-sm-row">
                    <Select
                      defaultValue="ALL"
                      style={{ width: "100%", maxWidth: "200px" }}
                      onChange={handleStatusChange}
                    >
                      <Option value="ALL">All Status</Option>
                      <Option value="PENDING">Pending</Option>
                      <Option value="APPROVED">Approved</Option>
                      <Option value="REJECTED">Rejected</Option>
                    </Select>
                    <DatePicker.RangePicker
                      style={{ width: "100%", maxWidth: "200px" }}
                      className="mx-3 mt-2 mt-sm-0 me-3"
                      onChange={handleDateChange}
                      format="YYYY-MM-DD"
                    />
                  </div>
                </div>
              </>
            }
            loading={loading}
          >
            <Table responsive>
              <thead>
                <tr className="text-start">
                  <th>S no.</th>
                  <th>Date</th>
                  <th>Wallet</th>
                  <th>USDC Amount</th>
                  <th>Status</th>
                  <th>Txid</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {!loading && withdrawalData.length > 0 ? (
                  withdrawalData.map((transaction, index) => (
                    <tr key={index}>
                      <td>{(pageNumber - 1) * pageSize + index + 1}</td>
                      <td>
                        {moment(transaction?.createdAt).format(
                          "DD/MM/YYYY, hh:mm:ss a"
                        )}
                      </td>
                      <td>
                        <a
                          target="_blank"
                          href={`https://suiscan.xyz/mainnet/account/${transaction?.wallet_address}`}
                          rel="noreferrer"
                        >
                          {formatAddress(transaction?.wallet_address)}
                        </a>
                        <IoMdCopy
                          className="text-primary"
                          onClick={() =>
                            copyToClipboard(transaction?.wallet_address)
                          }
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                      <td>{transaction?.usdc_amount}</td>
                      <td
                        className={
                          transaction?.status === "APPROVED"
                            ? "text-success"
                            : "text-danger"
                        }
                      >
                        <Badge
                          pill
                          bg={
                            transaction?.status === "APPROVED"
                              ? "success"
                              : transaction?.status === "PENDING"
                              ? "warning"
                              : "danger"
                          }
                        >
                          {transaction?.status}
                        </Badge>
                      </td>
                      <td>
                        <a
                          target="_blank"
                          href={`https://suiscan.xyz/mainnet/tx/${transaction?.transaction_hash}`}
                          rel="noreferrer"
                        >
                          {transaction?.transaction_hash
                            ? formatAddress(transaction?.transaction_hash)
                            : ""}
                        </a>
                        {transaction?.transaction_hash && (
                          <IoMdCopy
                            className="text-primary"
                            onClick={() =>
                              copyToClipboard(transaction?.transaction_hash)
                            }
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </td>
                      <td>
                        {transaction?.status === "PENDING" && (
                          <>
                            <a
                              className="withdraw-btn text-decoration-none text-success"
                              style={{ marginRight: "15px" }}
                              onClick={() =>
                                handleRejectTransaction(transaction?.id, "APPROVED")
                              }
                            >
                              Approve 
                            </a>
                            <a
                              className="withdraw-btn text-decoration-none text-danger"
                              style={{ marginRight: "15px" }}
                              onClick={() =>
                                handleRejectTransaction(transaction?.id, "REJECTED")
                              }
                            >
                              Reject
                            </a>
                          </>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="text-center">
                      {loading ? "Loading..." : "No transactions found"}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <div className="d-flex">
              <Pagination
                className="mx-auto mt-4"
                current={pageNumber}
                pageSize={pageSize}
                total={withdrawalData.length}
                onChange={handlePageChange}
                showSizeChanger
                onShowSizeChange={handlePageChange}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DepositTransaction;
