import React from "react";
import { Card } from "react-bootstrap";
import { Button, Form, Input } from "antd";
import axios from "axios"; // Import Axios
import Logo from "../../../Assets/Images/LogoDark.png";
import toast from "react-hot-toast";
import { BaseUrl } from "../../Common/Apis/Apis";

const Forgot = () => {
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    const email = values.email;
    
    const payload = {
      url: "http://localhost:3000",  
      email: email
    };

    try {
      const response = await axios.post(
BaseUrl+"admin/forgotPassword",
        payload,
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Password reset link has been sent to your email.");
    } catch (error) {
      console.error("Error:", error.response ? error.response.data : error.message);
      toast.error("Failed to send password reset link. Please try again.");
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <Card style={{ width: "40%", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)" }}>
        <Card.Body>
          <div className="d-flex justify-content-center">
            <img src={Logo} alt="Logo" className="img-fluid mb-4" width={150} />
          </div>
          {/* Form for email input */}
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            style={{ padding: "0 15px" }}
          >
            <Form.Item
              name="email"
              label="Email Address"
              rules={[
                {
                  required: true,
                  message: "Please enter your email!",
                },
                {
                  type: "email",
                  message: "Please enter a valid email address!",
                },
              ]}
            >
              <Input placeholder="Enter your email" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" block className="supplyBtn">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Forgot;
