import React, { useState, useEffect } from "react";
import { Form, Modal, Input, Tooltip, Button } from "antd";
import { MdOutlineInfo } from "react-icons/md";
import "../Supply/SupplyModal.css";
import { useWallet } from "@suiet/wallet-kit";
import toast from "react-hot-toast";
import { getConfig } from "../../Common/Address/Address";
import USDC from "../../../Assets/Svg/Usdc.svg";
import { apiToken, BaseUrl } from "../../Common/Apis/Apis";
import axios from "axios";
import sha256 from 'js-sha256';

const UserWithdrawModal = ({
  tokenBalances,
  tokenName,
  usdValue,
  netWorth,
  updatePosition,
  exchangerate,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [calculatedBalance, setCalculatedBalance] = useState("");
  const wallet = useWallet();
  const exchangeRate = exchangerate;
  const MaxAmt = (tokenBalances * exchangeRate)?.toFixed(5);
  const handleClick = async () => {
    if (!wallet) {
      alert("Please connect your Sui wallet first.");
      return;
    }

    if (calculatedBalance > MaxAmt) {
      toast.error("Entered Value is more than Balance.");
      return;
    }
    const walletAddress = wallet.account?.address;
    console.error("DDDDDDD", walletAddress)
    const digestKey = process.env?.REACT_APP_ENV === "DEV" ? process.env?.REACT_APP_DEV_WITHDRAWAL_DIGEST_KEY : process.env?.REACT_APP_PROD_WITHDRAWAL_DIGEST_KEY;
    
    const digest = sha256(walletAddress + calculatedBalance + tokenName + exchangeRate + digestKey);
    const signMessage = `SuiDollar USDC Vault. Withdrawal request authorization for ${calculatedBalance} USDC.\nDigest: ${digest}`;
    
    try {
      const signature = await wallet.signPersonalMessage({
        message: new TextEncoder().encode(signMessage),
        account: wallet.account,
      });

      if (!signature) {
        toast.error("Message signing failed. Please try again.");
        return;
      }
      const response = await axios.post(
        `${BaseUrl}client/withdrawalRequest`,
        {
          usdcWithdrawalAmount: calculatedBalance,
          walletAddress: wallet.account?.address,
          digest: digest
        },
        {
          headers: {
            Authorization: apiToken?.token,
            accept: "application/json",
          },
        }
      );
      updatePosition();
      handleOk();
      toast.success("Withdrawal Request Successful!");
    } catch (error) {
      console.error("Error making withdrawal transaction request:", error);
      toast.error(error?.response?.data?.error);
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
    setCalculatedBalance("");
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleMaxClick = () => {
    setCalculatedBalance(MaxAmt?.toString());
  };

//   const handleInputChange = (e) => {
//     let value = e.target.value;
//     setCalculatedBalance(value);
//   };
const handleInputChange = (e) => {
    let value = e.target.value;
    const regex = /^\d*\.?\d{0,5}$/;
    if (regex.test(value) || value === "") {
      setCalculatedBalance(value);
    }
  };
  

  const [config, setConfig] = useState(null);

  const loadConfig = async () => {
    let detail = await getConfig();
    setConfig(detail);
  };

  useEffect(() => {
    loadConfig();
  }, []);

  return (
    <>
      <span
        className="me-2"
        disabled={!wallet.connected && !wallet.account?.address}
        onClick={showModal}
      >
        Withdraw
      </span>
      <Modal
        title={`${tokenName} Withdraw`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className="supply-modal"
      >
        <Form layout="vertical">
          <Form.Item>
            <Tooltip title="USDC withdrawal amount">
              <div className="d-flex align-items-center">
                Amount <MdOutlineInfo className="ms-1" />
              </div>
            </Tooltip>
            <div className="amount-input">
              <div className="p-2 amount-info d-flex justify-content-between">
                <span>
                  {/* ${(calculatedBalance * usdValue)?.toFixed(2) || "0.00"} */}
                </span>
                <span>
                  Value of User Position: {Number(MaxAmt)?.toFixed(4) || "0.0"}
                </span>
              </div>
              <div className="d-flex">
                <Input
                  placeholder="0.00"
                  className="amount-value"
                  type="number"
                  value={calculatedBalance || ""}
                  onChange={handleInputChange}
                />
                <button
                  className="max-btn"
                  type="button"
                  onClick={handleMaxClick}
                >
                  MAX
                </button>
                <div className="mx-2 d-flex align-items-center">
                  <img
                    src={USDC}
                    alt=""
                    className="img-fluid me-1"
                    width={18}
                  />
                  <strong>{tokenName}</strong>
                </div>
              </div>
              <div className="mt-2 d-flex align-items-center"></div>
            </div>
          </Form.Item>
          <Button
            size="large"
            block
            disabled={
              calculatedBalance <= 0 || calculatedBalance > parseFloat(MaxAmt)
            }
            className="w-100 ant-btn-default"
            onClick={handleClick}
          >
            {calculatedBalance <= "0"
              ? "Enter Amount"
              : `Withdraw ${tokenName}`}
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default UserWithdrawModal;
