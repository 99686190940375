import React, { useEffect, useState } from "react";
import { Card, Flex, Skeleton } from "antd";
import { Table } from "react-bootstrap";
import "./User.css";
import { BaseUrl } from "../../Components/Common/Apis/Apis";
import axios from "axios";

const UserDashboard = ({ userbalances, setStopDeposit, setExchangerate }) => {
  const [position, setPosition] = useState(null);
  const [loading, setLoading] = useState(true);

  const UserPosition = async () => {
    try {
      const response = await axios.get(
        `${BaseUrl}client/getGlobalAndVaultDetails`
      );
      setPosition(response?.data?.data);
      if (response?.data?.data?.vaultStats[0]?.vault_capacity <= response?.data?.data?.globalDetails?.net_worth) {
        setStopDeposit(true);
      }
      setExchangerate(response?.data?.data?.globalDetails?.exchange_rate_value)
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    UserPosition();
  }, []);

  const globleValue = position?.globalDetails;
  const vaultStats = position?.vaultStats[0];
  const tvl = globleValue?.net_worth || 0;
  const UserNetworth =(userbalances || 0) * (globleValue?.exchange_rate_value || 0) || 0;
  const formatWithCommas = (amount) => {
    return amount?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div>
      <h1 className="vault-heading">Delta Neutral USDC Vault</h1>
      <Flex gap="middle" align="start" vertical>
        <Card title="Vault Information" className="vault-table">
          <Table>
            <tbody>
              <tr className="text-start">
                <td>Total Value Locked (TVL)</td>
                <td>
                  {loading ? (
                    <Skeleton.Input active={true} size="small" />
                  ) : (
                    `${formatWithCommas(tvl?.toFixed(2))} USDC`
                  )}
                </td>
              </tr>
              <tr className="text-start">
                <td>APY</td>
                <td className="text-success">
                  <strong>
                    {loading ? (
                      <Skeleton.Input active={true} size="small" />
                    ) : (
                      `${(globleValue?.apy)?.toFixed(2) || "0.0"}%`
                    )}
                  </strong>
                </td>
              </tr>
              <tr className="text-start">
                <td>Vault Capacity</td>
                <td>
                  {loading ? (
                    <Skeleton.Input active={true} size="small" />
                  ) : (
                    `${formatWithCommas(vaultStats?.vault_capacity)} USDC`
                  )}
                </td>
              </tr>
              <tr className="text-start">
                <td>Value of User Position</td>
                <td>
                  {loading ? (
                    <Skeleton.Input active={true} size="small" />
                  ) : (
                    `${UserNetworth?.toFixed(2) || "0.0"} USDC`
                  )}
                </td>
              </tr>
              <tr className="text-start">
                <td>Deposit Fee</td>
                <td>
                  {loading ? (
                    <Skeleton.Input active={true} size="small" />
                  ) : (
                    `${vaultStats?.user_deposit_value / 100}%`
                  )}
                </td>
              </tr>
              <tr className="text-start">
                <td>Performance Fee</td>
                <td>
                  {loading ? (
                    <Skeleton.Input active={true} size="small" />
                  ) : (
                    `${
                      [0, "0", undefined, NaN].includes(
                        vaultStats?.performance_fee
                      )
                        ? "None"
                        : `${vaultStats?.performance_fee}%`
                    }`
                  )}
                </td>
              </tr>
              <tr className="text-start">
                <td>Withdrawal Processing</td>
                <td>
                  {loading ? (
                    <Skeleton.Input active={true} size="small" />
                  ) : (
                    vaultStats?.withdrawal_processsing
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </Card>
      </Flex>
    </div>
  );
};

export default UserDashboard;
