import React, { useState } from "react";
import { Button, Input, message, Modal } from "antd";
import { useWallet } from "@suiet/wallet-kit";
import { Transaction } from "@mysten/sui/transactions";
import { FaUserLock } from "react-icons/fa";
const AdminStake = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [days, setDays] = useState("");

  const wallet = useWallet();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCooing = async () => {
    if (!days || isNaN(days) || Number(days) <= 0) {
      message.error("Please enter a valid Days.");
      return;
    }
    try {
      const txb = new Transaction();
      const FairmilePackage =
        process.env.REACT_APP_ENV === "DEV"
          ? process.env.REACT_APP_DEV_PACKAGE_ID
          : process.env.REACT_APP_PROD_PACKAGE_ID;
      const FairmileModule = "teststaking";
      txb.moveCall({
        target: `${FairmilePackage}::${FairmileModule}::updateCoolingTime`,
        arguments: [
          txb.pure.u64(days),
          txb.object(
            "0xd7108e0a567605cabcc5979f948da090e14aa6ef3fca8e5560f1078124d82c70"
          ),
        ],
      });
      const result = await wallet.signAndExecuteTransactionBlock({
        transactionBlock: txb,
      });
      message.success("Tokens staked successfully!");
      console.log("Transaction result:", result);
    } catch (error) {
      message.error("Staking failed. Please try again.");
      console.error("Staking error:", error);
    }
  };

  return (
    <>
      <span onClick={showModal}>
        <FaUserLock className="text-success mx-3 fs-5" />
      </span>
      <Modal
        title="Cooling Period"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <div className="text-center">
          <Input
            value={days}
            onChange={(e) => setDays(e.target.value)}
            required
            placeholder="Enter No. of days..."
          />
          <Button className="mt-3" onClick={handleCooing}>
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
};
export default AdminStake;
