import React, { useEffect, useState } from "react";
import { Card, Flex } from "antd";
import Logo from "../../Assets/Images/01-05.png";
import MoveBitLogo from "../../Assets/Images/MoveBit.png";
import TelegramLogo from "../../Assets/Images/Telegram.svg";
import TwitterLogo from "../../Assets/Images/Twitter.png";
import axios from "axios";
import { BaseUrl } from "../../Components/Common/Apis/Apis";
import { Link } from "react-router-dom";


const formatWithCommas = (amount) => {
  if (amount) {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  else {
    return "0.0"
  }
};


const LandingPage = () => {
  const [position, setPosition] = useState();
  const [loading, isLoading] = useState(false);
  const UserPosition = async () => {
    isLoading(true)
    try {
      const response = await axios.get(
        `${BaseUrl}client/getGlobalAndVaultDetails`
      );
      setPosition(response?.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }finally{
      isLoading(false)
    }
  };
  useEffect(() => {
    UserPosition();
  }, []);

  const globleValue = position?.globalDetails;

  return (
    <div>
      <div style={{ padding: "20px" }}>
        <div className="mx-auto my-auto">
          <div>
            <Flex gap="middle" align="start" vertical>
              <Card className="landing-card">
                <div className="landing-content">
                  <div className="landing-text">
                    <h1>
                      The No. 1 Delta Neutral USDC Vault.
                      <br />
                      Real Yield.
                      <br />
                      Built on <span>Sui.</span>
                    </h1>
                    <div className="stats">
                      <p>
                        TVL<span>${loading === true ? "--" : formatWithCommas((globleValue?.net_worth)?.toFixed(2))}</span>
                      </p>
                      <p>
                        APY<span>{loading === true ? "--" : (globleValue?.apy)?.toFixed(2) || "0.0"}%</span>
                      </p>
                    </div>
                    <div className="audited-by">
                      <span>
                        Audited by
                        <img
                          src={MoveBitLogo}
                          alt=""
                          className="image-fluid me-3"
                          width={250}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="landing-image">
                    <img
                      src={Logo}
                      alt=""
                      className="image-fluid me-3"
                      width={700}
                    />
                  </div>
                </div>
              </Card>
            </Flex>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="socials">
          <a href="https://x.com/suidollar" target="_blank" rel="noreferrer"><img src={TwitterLogo} alt="" className="image-fluid" width={35}/></a>
          <a href="https://t.me/+CXPIu_Qy2ydkMjc0" target="_blank" rel="noreferrer"><img src={TelegramLogo} alt="" className="image-fluid" width={80}/></a>
        </div>
        <div>
          <ul>
            <li>
              <Link to={"/vault"}>Vault</Link>
            </li>
            <li>
              <a
                href="https://suidollar.gitbook.io/overview"
                target="_blank"
                rel="noreferrer"
              >
                Documentation
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
