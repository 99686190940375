import { Transaction } from "@mysten/sui/transactions";
import { getConfig, pool } from "../../Common/Address/Address";
import { toast } from "react-hot-toast";
import Swal from "sweetalert2";
import { FaRegCopy } from "react-icons/fa";
import React from "react";
import ReactDOMServer from "react-dom/server";
import { Suiscan } from "../../Common/Apis/Apis";

export async function CreateTreasury(wallet, tokenName) {
  const config = await getConfig();

  const txb = new Transaction();
  const tokenDetails = {
    SUI: {
      type: pool.Sui.type,
    },
    USDC: {
      type: pool.NUSDC.type,
    },
    wUSDC: {
      type: pool.USDC.type,
    },
    WETH: {
      type: pool.WETH.type,
    },
  };

  const selectedToken = tokenDetails[tokenName];

  if (tokenName === "SUI") {
    txb.moveCall({
      target: `${config.FairmilePackage}::${config.FairmileModule}::create_treasury`,
      typeArguments: [selectedToken?.type],
    });
  } else if (tokenName === "USDC") {
    txb.moveCall({
      target: `${config.FairmilePackage}::${config.FairmileModule}::create_treasury`,
      typeArguments: [selectedToken?.type],
    });
  }else if (tokenName === "wUSDC") {
    txb.moveCall({
      target: `${config.FairmilePackage}::${config.FairmileModule}::create_treasury`,
      typeArguments: [selectedToken?.type],
    });
  }
   else {
    txb.moveCall({
      target: `${config.FairmilePackage}::${config.FairmileModule}::create_treasury`,
      typeArguments: [selectedToken?.type],
    });
  }

  try {
    const result = await wallet?.signAndExecuteTransactionBlock({
      transactionBlock: txb,
    });

    // Format the hash to display the first and last 6 characters with "..."
    const formattedHash = `${result.digest.slice(0, 7)}...${result.digest.slice(
      -7
    )}`;

    Swal.fire({
      title: `${tokenName} Treasury Created Successfully`,
      timer: 60000,
      html: ReactDOMServer.renderToString(
        <>
          <div className="d-flex justify-content-center">
            <span>Transaction Hash:</span>
            <br />
            <div className="d-flex align-items-center ms-2">
              <a
                href={Suiscan + `${result.digest}`}
                style={{ textDecoration: "none", color: "black" }}
                target="_blank"
                rel="noreferrer"
              >
                <strong>{formattedHash}</strong>
              </a>
              <FaRegCopy
                id="copyHash"
                className="swal2-confirm swal2-styled"
                style={{
                  alignItems: "center",
                  gap: "5px",
                  marginLeft: "10px",
                }}
                onClick={() => navigator.clipboard.writeText(result.digest)}
              />
            </div>
          </div>
        </>
      ),
      icon: "success",
      showConfirmButton: false,
      didRender: () => {
        const copyButton = document.getElementById("copyHash");
        copyButton.addEventListener("click", () => {
          navigator.clipboard.writeText(result.digest).then(() => {
            toast.success("Transaction hash copied to clipboard.");
          });
        });
      },
    });
  } catch (error) {
    toast.error(
      error.message === "[WALLET.SIGN_TX_ERROR] Rejected by user"
        ? "Rejected by user"
        : error.message || "Transaction failed"
    );
    console.error("Error executing transaction:", error);
  }
}
