import React, { useState, useEffect } from "react";
import { Form, Modal, Input, Tooltip, Button, Slider } from "antd";
import { GiGasPump } from "react-icons/gi";
import { MdOutlineInfo } from "react-icons/md";
import "./SupplyModal.css";
import Sui from "../../../Assets/Svg/Sui.svg";
import WETH from "../../../Assets/Svg/Weth.svg";
import USDC from "../../../Assets/Svg/Usdc.svg";
import { depositCoinWithAccountCap } from "./Supply";
import { useWallet } from "@suiet/wallet-kit";
import toast from "react-hot-toast";
import { getConfig } from "../../Common/Address/Address";
import axios from "axios";
import { BaseUrl, getIndexAssetData } from "../../Common/Apis/Apis";

const SupplyModal = ({
  tokenName,
  tokenApy,
  usdValue,
  CoinObjectId,
  fetchTreasureWethBalance,
  fetchTreasureUsdcBalance,
  fetchTreasureBalance,
  treasureSuiBalances,
  treasureUsdcBalances,
  treasureUsdcNative,
  treasureWethBalances,
  handleReload,
  reload,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [calculatedBalance, setCalculatedBalance] = useState("");
  const [config, setConfig] = useState(null);
  const [health, setHealth] = useState("-");
  const adminTokenToken = sessionStorage?.getItem("token");
  const wallet = useWallet();

  const tokenBalances =
    tokenName === "SUI"
      ? treasureSuiBalances
      : tokenName === "wUSDC"
      ? treasureUsdcBalances
      : tokenName === "USDC"
      ? treasureUsdcNative
      : treasureWethBalances;

  const handleClick = async () => {
    if (!wallet) {
      toast.error("Please connect your Sui wallet first.");
      return;
    }
    if (calculatedBalance > tokenBalances) {
      toast.error("Entered Value is more then treasury Balance.");
      return;
    }
    try {
      const result = await depositCoinWithAccountCap(
        wallet,
        calculatedBalance,
        tokenName,
        fetchTreasureWethBalance,
        fetchTreasureUsdcBalance,
        fetchTreasureBalance
      );
      handleOk();
      handleReload();
      reload();
    } catch (error) {
      console.error("Transaction failed:", error);
    }
  };

  var newHealthFactorId = "new_health_factor_supply_" + tokenName;
  const showModal = () => {
    setIsModalOpen(true);
    setCalculatedBalance("");
    fetchHealth();
    fetchStakesByIds();
    fetchTokenInfo();
    var newHF = document.getElementById(newHealthFactorId);
    if (newHF != null) {
      newHF.textContent = "";
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  //const handleSliderChange = (value) => {
  //setSliderValue(value);
  //const balance = (value / tokenBalances) * 100;
  //setCalculatedBalance(balance.toFixed(4));
  //};

  const handleMaxClick = () => {
    setCalculatedBalance(tokenBalances);
    updateHealthFactor(tokenBalances);
    //setSliderValue(100);
  };

  //useEffect(() => {
  //  const balance = (sliderValue / 100) * tokenBalances;
  //  setCalculatedBalance(balance);
  //}, [sliderValue, tokenBalances]);

  const marks = {
    0: "0",
    25: "25",
    50: "50",
    75: "75",
    100: "100",
  };
  const loadConfig = async () => {
    let detail = await getConfig();
    setConfig(detail);
  };

  const mode = process.env.REACT_APP_MODE;
  const naviAddAccountCap =
    mode === "DEV"
      ? process.env.REACT_APP_NAVI_ACC_DEV
      : process.env.REACT_APP_NAVI_ACC_PROD;

  const fetchHealth = async () => {
    try {
      const response = await axios.get(
        `${BaseUrl}admin/getHealthFactor?key=${naviAddAccountCap}`,
        {
          headers: {
            Authorization: adminTokenToken.replace(/^"|"$/g, ""),
          },
        }
      );
      setHealth(response?.data?.data?.health.toFixed(2) || "0.00");
    } catch (err) {
      console.error(err.message);
      setHealth("0.00");
    }
  };

  const [assetsInfo, setAssetsInfo] = useState({});
  const fetchTokenInfo = async () => {
    try {
      const response = await axios.get(getIndexAssetData);
      setAssetsInfo(response.data);
    } catch (error) {
      console.error("Error fetching token info:", error);
    }
  };

  const assetData = Object.values(assetsInfo)
    .filter((info) => ["USDC", "SUI", "WETH"].includes(info.symbol))
    .map((info) => ({
      price: info.price,
      name: info.symbol,
    }));

  const [stakes, setStakes] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const fetchStakesByIds = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        BaseUrl + `admin/getNaviPortfolio?key=${naviAddAccountCap}`,
        {
          headers: {
            Authorization: adminTokenToken.replace(/^"|"$/g, ""),
          },
        }
      );
      setStakes(response?.data?.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const suiUsdDebt = stakes?.SUI?.borrowBalance * assetData[0]?.price;
  const usdcUsdDebt = stakes?.USDC?.borrowBalance * assetData[1]?.price;
  const wethUsdDebt = stakes?.WETH?.borrowBalance * assetData[2]?.price;
  const totalUsdDebt = suiUsdDebt + usdcUsdDebt + wethUsdDebt;

  const suiUsdSupply = stakes?.SUI?.supplyBalance * assetData[0]?.price;
  const usdcUsdSupply = stakes?.USDC?.supplyBalance * assetData[1]?.price;
  const wethUsdSupply = stakes?.WETH?.supplyBalance * assetData[2]?.price;

  const suiLiqThreshold = 0.8;
  const usdcLiqThreshold = 0.85;
  const wethLiqThreshold = 0.8;

  const handleInputChange = (e) => {
    let value = e.target.value;
    if (isNaN(value)) {
      value = 0;
    }
    setCalculatedBalance(value);
    updateHealthFactor(value);
    //setSliderValue((value / tokenBalances) * 100);
  };

  const updateHealthFactor = (value) => {
    var newHF = 0;
    if (tokenName == "SUI") {
      const newSuiUsdSupply = suiUsdSupply + value * assetData[0]?.price;
      newHF =
        (newSuiUsdSupply * suiLiqThreshold +
          usdcUsdSupply * usdcLiqThreshold +
          wethUsdSupply * wethLiqThreshold) /
        totalUsdDebt;
    }
    if (tokenName == "wUSDC") {
      const newUsdcUsdSupply = usdcUsdSupply + value * assetData[1]?.price;
      newHF =
        (suiUsdSupply * suiLiqThreshold +
          newUsdcUsdSupply * usdcLiqThreshold +
          wethUsdSupply * wethLiqThreshold) /
        totalUsdDebt;
    }
    if (tokenName == "WETH") {
      const newWethcUsdSupply = wethUsdSupply + value * assetData[2]?.price;
      newHF =
        (suiUsdSupply * suiLiqThreshold +
          usdcUsdSupply * usdcLiqThreshold +
          newWethcUsdSupply * wethLiqThreshold) /
        totalUsdDebt;
    }
    var healthElement = document.getElementById(
      "new_health_factor_supply_" + tokenName
    );
    if (parseFloat(newHF) <= 100) {
      healthElement.textContent = parseFloat(newHF).toFixed(2);
    } else {
      healthElement.textContent = "Infinity";
    }
  };

  useEffect(() => {
    loadConfig();
  }, []);

  return (
    <>
      {(tokenName === "SUI" && config?.suiTreasury) ||
      (tokenName === "wUSDC" && config?.usdcNativeTreasury) ||
      (tokenName === "USDC" && config?.usdcTreasury) ||
      (tokenName === "WETH" && config?.wethTreasury) ? (
        <span
          className="me-2 "
          disabled={!wallet?.connected && !wallet?.account?.address}
          onClick={showModal}
        >
          Navi Supply
        </span>
      ) : (
        ""
      )}
      <Modal
        title={` ${tokenName} Navi Supply`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className="supply-modal"
      >
        <Form layout="vertical">
          <Form.Item>
            <Tooltip title="Total amount of assets to supply">
              <div className="d-flex align-items-center">
                Amount <MdOutlineInfo className="ms-1" />
              </div>
            </Tooltip>
            <div className="amount-input">
              <div className="p-2 amount-info d-flex justify-content-between">
                <span>
                  <span>
                    ${(calculatedBalance * usdValue).toFixed(2) || "0.00"}
                  </span>
                </span>
                <span>
                  Treasury {tokenName} Balance: {tokenBalances.toFixed(4)}
                </span>
              </div>
              <div className="d-flex">
                <Input
                  placeholder="0.00"
                  className="amount-value"
                  type="number"
                  min={0}
                  max={tokenBalances}
                  value={calculatedBalance}
                  onChange={handleInputChange}
                />
                <button
                  className="max-btn"
                  type="button"
                  onClick={handleMaxClick}
                >
                  MAX
                </button>
                <div className="mx-2 d-flex align-items-center">
                  <img
                    src={
                      tokenName === "SUI"
                        ? Sui
                        : tokenName === "WETH"
                        ? WETH
                        : USDC
                    }
                    alt=""
                    className="img-fluid me-1"
                    width={18}
                  />
                  <strong>{tokenName}</strong>
                </div>
              </div>
              <div className="mt-2 d-flex align-items-center"></div>
            </div>
          </Form.Item>
          {/*<Slider
            marks={marks}
            value={sliderValue}
            onChange={handleSliderChange}
          />*/}
          <Form.Item>
            <span>Transaction Overview</span>
            <div className="transaction-overview">
              <div className="mb-2 d-flex justify-content-between">
                <span>Current Health Factor</span>
                <span>{health}</span>
              </div>
              <div className="mb-2 d-flex justify-content-between">
                <span>Supply Amount</span>
                <span>{calculatedBalance <= 0 ? "" : calculatedBalance}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span>New Health Factor</span>
                <span id={newHealthFactorId}></span>
              </div>
            </div>
          </Form.Item>
          <Button
            size="large"
            block
            disabled={
              calculatedBalance <= 0 ||
              calculatedBalance > parseFloat(tokenBalances)
            }
            className="supplyBtn"
            onClick={handleClick}
          >
            {calculatedBalance <= 0 ? "Enter Amount" : `Supply ${tokenName}`}
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default SupplyModal;
