import { Transaction } from "@mysten/sui/transactions";
import { getConfig, pool } from "../../Common/Address/Address";
import toast from "react-hot-toast";

export async function withdrawfee(
  wallet,
  amount,
  tokenName
) {
  const config = await getConfig();
  const walletAddress = wallet.account.address;
  const amountInNanoUnits =Math.floor((amount-(amount*0.001)) * 1e6)
  const tokenDetails = {
    USDC: {
      poolId: pool.USDC.poolId,
      assetId: pool.USDC.assetId,
      type: pool.NUSDC.type,
    },
    wUSDC: {
      poolId: pool.USDC.poolId,
      assetId: pool.USDC.assetId,
      type: pool.USDC.type,
    },
  };

  const selectedToken = tokenDetails[tokenName];
    const txb = new Transaction();
    if(tokenName==="wUSDC"){
      txb.moveCall({
        target: `${config.FairmilePackage}::${config.FairmileModule}::withdrawFees`,
        arguments: [
          txb.object(config.usdcTreasury),
          txb.pure.u64(amountInNanoUnits),
        ],
        typeArguments: [selectedToken.type],
      });
    }else{
      txb.moveCall({
        target: `${config.FairmilePackage}::${config.FairmileModule}::withdrawFees`,
        arguments: [
          txb.object(config.usdcNativeTreasury),
          txb.pure.u64(amountInNanoUnits),
        ],
        typeArguments: [selectedToken.type],
      });
    }
    try {
      const result = await wallet.signAndExecuteTransactionBlock({
        transactionBlock: txb,
      });
      toast.success("Transaction successful!");
    } catch (error) {
      toast.error(
        error?.message === "[WALLET.SIGN_TX_ERROR] Rejected from user"
          ? "Rejected from user"
          : error?.message || "Transaction failed!"
      );
      console.error("Error executing transaction:", error);
    }
}