import { Transaction } from "@mysten/sui/transactions";
import { getConfig, pool } from "../../Common/Address/Address";
import { toast } from "react-hot-toast";
import axios from "axios";
import { BaseUrl } from "../../Common/Apis/Apis";

export async function withdrawApprovel(
  calculatedBalance,
  walletAddress,
  wallet,
  UserId,
  userPosition,
  networth,
  tokenBalances
) {
  const config = await getConfig();
  const txb = new Transaction();
  const amountInNanoUnits = Math.floor(tokenBalances * 1e6);
  const tokenDetails = {
    USDC: {
      poolId: pool.USDC.poolId,
      assetId: pool.USDC.assetId,
      type: pool.NUSDC.type,
    },
  };
  const selectedToken = tokenDetails["USDC"];

  txb.moveCall({
    target: `${config.FairmilePackage}::${config.FairmileModule}::userWithdraw`,
    arguments: [
      txb.object(config.usdcNativeTreasury),
      txb.pure.address(walletAddress),
      txb.pure.u64(amountInNanoUnits),
    ],
    typeArguments: [selectedToken.type],
  });

  try {
    const result = await wallet.signAndExecuteTransactionBlock({
      transactionBlock: txb,
    });
    //api here
    const apiToken = sessionStorage?.getItem("token");
    
    const apiResponse = await axios.post(
      BaseUrl + "admin/withdrawalTransaction",
      {
        usdcWithdrawalAmount: tokenBalances,
        totalUserPosition: userPosition,
        contractNetWorth: networth,
        usdcAmount: tokenBalances,
        TransactionHash: result.digest,
        id: UserId,
      },
      {
        headers: {
          Authorization: apiToken.replace(/^"|"$/g, ""),
          "Content-Type": "application/json",
        },
      }
    );
    toast.success("Transaction successful!");
  } catch (error) {
    toast.error(
      error.message === "[WALLET.SIGN_TX_ERROR] Rejected from user"
        ? "Rejected from user"
        : error.message || "Transaction failed!"
    );
    console.error("Error executing transaction:", error);
  }
}