import { useState } from "react";
import { FaCopy } from "react-icons/fa";
import { GiCheckMark } from "react-icons/gi";

export const Copy = ({ content }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(content);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <div onClick={copyToClipboard} style={{ cursor: "pointer" }} className="ms-2 d-inline ">
      {copied ? (
        <GiCheckMark style={{ color: "#00ADEF" }} />
      ) : (
        <FaCopy style={{ color: "#00ADEF" }} />
      )}
    </div>
  );
};
