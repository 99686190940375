import React, { useEffect, useState } from "react";
import {
  useWallet,
  ConnectModal,
  ErrorCode,
  useAccountBalance,
} from "@suiet/wallet-kit";
import { Dropdown, Button, Menu, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import axios from "axios";
import { BaseUrl } from "../Common/Apis/Apis";
import toast from "react-hot-toast";

function ConnectWallet() {
  const wallet = useWallet();
  const { balance } = useAccountBalance();
  const WalletBalance = Number(balance) / 1e9;
  const [showModal, setShowModal] = useState(false);
  const [addressDisplay, setAddressDisplay] = useState("");
  const [userSign, setUserSign] = useState(null);

  // Function to check if the user is new or returning
  const handleUserSign = async () => {
    if (wallet.account?.address) {
      try {
        const response = await axios.get(
          `${BaseUrl}client/checkUserStatus?walletAddress=${wallet.account?.address}`,
          {
            walletAddress: wallet?.account?.address,
          }
        );
        setUserSign(response?.data?.data || null);
        return response?.data?.data;
      } catch (error) {
        toast.error(error?.response?.data?.error || "Something went wrong.");
        return null;
      }
    }
    return null;
  };

  const handleLogin = async () => {
    const userStatus = await handleUserSign();
    if (userStatus === "true") {
      try {
        const response = await axios.post(`${BaseUrl}client/login`, {
          walletAddress: wallet?.account?.address,
        });
        sessionStorage.setItem("UserAuth", JSON.stringify(response?.data?.data));
        // setUserSign(response?.data?.data?.clientData);
      } catch (error) {
        toast.error(
          error?.response?.data?.error || "Login failed. Please try again."
        );
      }
    } else if (userStatus === "false") {
      const signMessage = `By using the SuiDollar platform, you confirm and agree to the following:
    • You do not reside in, are a citizen of, are located in, or have a registered office in the US or any other restricted country.
    • You are not using a VPN to circumvent these restrictions.
    • You are in compliance with your local jurisdictional laws.
    • You understand that using the SuiDollar platform contains risk, and may result in the partial or total loss of your funds.`;

      try {
        const signature = await wallet.signMessage({
          message: new TextEncoder().encode(signMessage),
        });

        const response = await axios.post(`${BaseUrl}client/login`, {
          walletAddress: wallet?.account?.address,
        });
        sessionStorage.setItem("UserAuth", JSON.stringify(response?.data?.data));

        if (!signature) {
          toast.error("Message signing failed. Please try again.");
          handleDisconnect();
          return;
        }
      } catch (error) {
        console.error("Error making withdrawal transaction request:", error);
        toast.error("Rejected by user ");
        handleDisconnect();
      }
    }
  };

  useEffect(() => {
    if (wallet.connected && wallet.account?.address) {
      const shortenedAddress = `${wallet.account.address.slice(0, 5)}...`;
      setAddressDisplay(shortenedAddress);
      setShowModal(false);
      handleLogin();
    } else {
      sessionStorage.removeItem("walletAddress" && "UserAuth");
    }
  }, [wallet.connected, wallet.account?.address]);

  const handleDisconnect = () => {
    wallet.disconnect();
    setAddressDisplay("");
  };

  const menu = (
    <Menu className="text-white bg-dark DisconnectWalletBtn">
      <Menu.Item
        key="1"
        className="text-center text-white bg-dark DisconnectWallet"
        onClick={handleDisconnect}
      >
        <strong className="fw-bold">Disconnect Wallet</strong>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      {wallet.connected ? (
        <Dropdown overlay={menu} trigger={["click"]}>
          <Space>
            <Button className="ConnectedWallet">
              {WalletBalance?.toFixed(2)} SUI {addressDisplay} <DownOutlined />
            </Button>
          </Space>
        </Dropdown>
      ) : (
        <span
          onClick={() => setShowModal(true)}
          className="px-3 py-2 rounded ConnectedWallet"
        >
          Connect Wallet
        </span>
      )}

      <ConnectModal
        open={showModal}
        onOpenChange={setShowModal}
        onConnectError={(error) => {
          if (error.code === ErrorCode.WALLET__CONNECT_ERROR__USER_REJECTED) {
            console.warn("user rejected the connection");
            toast.error("User rejected the connection");
          } else {
            console.warn("Unknown connect error: ", error);
            toast.error("Unknown connect error: " + error.message);
          }
        }}
      />
    </div>
  );
}

export default ConnectWallet;
