import { Transaction } from "@mysten/sui/transactions";
import { getConfig, pool } from "../../Common/Address/Address";
import toast from "react-hot-toast";

export async function BorrowCoinWithAccountCap(wallet, amount, tokenName) {
  const config = await getConfig();
  const amountInNanoUnits =
    tokenName === "SUI"
      ? Math.floor(amount * 1e9)
      : tokenName === "WETH"
      ? Math.floor(amount * 1e8)
      : tokenName === "wUSDC"
      ? Math.floor(amount * 1e6)
      : Math.floor(amount * 1e6);

  const tokenDetails = {
    SUI: {
      poolId: pool.Sui.poolId,
      assetId: pool.Sui.assetId,
      type: pool.Sui.type,
    },
    USDC: {
      poolId: pool.NUSDC.poolId,
      assetId: pool.NUSDC.assetId,
      type: pool.NUSDC.type,
    },
    wUSDC: {
      poolId: pool.USDC.poolId,
      assetId: pool.USDC.assetId,
      type: pool.USDC.type,
    },
    WETH: {
      poolId: pool.WETH.poolId,
      assetId: pool.WETH.assetId,
      type: pool.WETH.type,
    },
  };
  const selectedToken = tokenDetails[tokenName];
  if (tokenName === "SUI") {
    const txb = new Transaction();
    txb.moveCall({
      target: `${config.FairmilePackage}::${config.FairmileModule}::borrow`,
      arguments: [
        txb.object(config.suiTreasury), 
        txb.object(config?.myStruct),
        txb.object(config?.ClockID),
        txb.object(config?.PriceOracle),
        txb.object(config?.StorageId),
        txb.object(selectedToken?.poolId),
        txb.pure.u8(selectedToken?.assetId),
        txb.pure.u64(amountInNanoUnits),
        txb.object(config?.IncentiveV2),
      ],
      typeArguments: [selectedToken?.type],
    });
    try {
      const result = await wallet?.signAndExecuteTransactionBlock({
        transactionBlock: txb,
      });
      toast.success("Transaction successful");
    } catch (error) {
      toast.error(
        error?.message === "[WALLET.SIGN_TX_ERROR] Rejected by user"
          ? "Rejected by user"
          : error?.message || "Transaction failed"
      );
      console.error("Error executing transaction:", error);
    }
  } else if (tokenName === "USDC") {
    const txb = new Transaction();
    txb.moveCall({
      target: `${config.FairmilePackage}::${config.FairmileModule}::borrow`,
      arguments: [
        txb.object(config.usdcNativeTreasury), 
        txb.object(config?.myStruct),
        txb.object(config?.ClockID),
        txb.object(config?.PriceOracle),
        txb.object(config?.StorageId),
        txb.object(selectedToken?.poolId),
        txb.pure.u8(selectedToken?.assetId),
        txb.pure.u64(amountInNanoUnits),
        txb.object(config?.IncentiveV2),
      ],
      typeArguments: [selectedToken?.type],
    });
    try {
      const result = await wallet?.signAndExecuteTransactionBlock({
        transactionBlock: txb,
      });
      toast.success("Transaction successful");
    } catch (error) {
      toast.error(
        error?.message === "[WALLET.SIGN_TX_ERROR] Rejected by user"
          ? "Rejected by user"
          : error?.message || "Transaction failed"
      );
      console.error("Error executing transaction:", error);
    }
  } else if (tokenName === "wUSDC") {
    const txb = new Transaction();
    txb.moveCall({
      target: `${config.FairmilePackage}::${config.FairmileModule}::borrow`,
      arguments: [
        txb.object(config.usdcTreasury), 
        txb.object(config?.myStruct),
        txb.object(config?.ClockID),
        txb.object(config?.PriceOracle),
        txb.object(config?.StorageId),
        txb.object(selectedToken?.poolId),
        txb.pure.u8(selectedToken?.assetId),
        txb.pure.u64(amountInNanoUnits),
        txb.object(config?.IncentiveV2),
      ],
      typeArguments: [selectedToken?.type],
    });
    try {
      const result = await wallet?.signAndExecuteTransactionBlock({
        transactionBlock: txb,
      });
      toast.success("Transaction successful");
    } catch (error) {
      toast.error(
        error?.message === "[WALLET.SIGN_TX_ERROR] Rejected by user"
          ? "Rejected by user"
          : error?.message || "Transaction failed"
      );
      console.error("Error executing transaction:", error);
    }
  } else {
    const txb = new Transaction();
    txb.moveCall({
      target: `${config.FairmilePackage}::${config.FairmileModule}::borrow`,
      arguments: [
        txb.object(config.wethTreasury), 
        txb.object(config?.myStruct),
        txb.object(config?.ClockID),
        txb.object(config?.PriceOracle),
        txb.object(config?.StorageId),
        txb.object(selectedToken?.poolId),
        txb.pure.u8(selectedToken?.assetId),
        txb.pure.u64(amountInNanoUnits),
        txb.object(config?.IncentiveV2),
      ],
      typeArguments: [selectedToken?.type],
    });
    try {
      const result = await wallet?.signAndExecuteTransactionBlock({
        transactionBlock: txb,
      });
      toast.success("Transaction successful");
    } catch (error) {
      toast.error(
        error?.message === "[WALLET.SIGN_TX_ERROR] Rejected by user"
          ? "Rejected by user"
          : error?.message || "Transaction failed"
      );
      console.error("Error executing transaction:", error);
    }
  }
}
