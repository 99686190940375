import React, { useState, useEffect } from "react";
import { Form, Modal, Input, Tooltip, Button } from "antd";
import { MdOutlineInfo } from "react-icons/md";
import "./SupplyModal.css";
import Sui from "../../../Assets/Svg/Sui.svg";
import WETH from "../../../Assets/Svg/Weth.svg";
import USDC from "../../../Assets/Svg/Usdc.svg";
import { useWallet } from "@suiet/wallet-kit";
import { deposit } from "./Deposit";
import toast from "react-hot-toast";
import { getConfig } from "../../Common/Address/Address";
import { CreateTreasury } from "./CreateTreasury";

const DepositModal = ({
  tokenName,
  tokenBalances,
  usdValue,
  CoinObjectId,
  fetchTreasureWethBalance,
  fetchTreasureUsdcBalance,
  fetchTreasureBalance,
  handleReload,
  fetchBalances
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [calculatedBalance, setCalculatedBalance] = useState("");
  const wallet = useWallet();
  
  const handleClick = async () => {
    if (!wallet) {
      alert("Please connect your Sui wallet first.");
      return;
    }
    if (calculatedBalance > tokenBalances) {
      toast.error("Entered Value is more then Wallet Balance.");
      return;
    }

    try {
      const result = await deposit(
        wallet,
        calculatedBalance,
        CoinObjectId,
        tokenName,
        fetchTreasureWethBalance,
        fetchTreasureUsdcBalance,
        fetchTreasureBalance
      );
      handleCancel();
      handleReload();
      fetchBalances()
      setTimeout(() => {
        fetchBalances();
      }, 3000);
    } catch (error) {
      console.error("Transaction failed:", error);
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
    setCalculatedBalance("");
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleMaxClick = () => {
    setCalculatedBalance(tokenBalances.toString());
  };

  const handleInputChange = (e) => {
    let value = e.target.value;
    // value = Math.max(0, Math.min(value, tokenBalances));
    // const percentage = (value / tokenBalances) * 100;
    setCalculatedBalance(value);
  };

  const TreasuryInitiate = async () => {
    try {
      const TreasuryStart = await CreateTreasury(wallet, tokenName);
      handleCancel();
      handleReload();
    } catch (error) {
      console.error("Transaction failed:", error);
    }
  };

  const [config, setConfig] = useState(null);

  const loadConfig = async () => {
    let detail = await getConfig();
    setConfig(detail);
  };
  useEffect(() => {
    loadConfig();
  }, []);
  return (
    <>
      {(tokenName === "SUI" && config?.suiTreasury) ||
      (tokenName === "wUSDC" && config?.usdcTreasury) ||
      (tokenName === "USDC" && config?.usdcNativeTreasury) ||
      (tokenName === "WETH" && config?.wethTreasury) ? (
        <span
          className="me-2"
          disabled={!wallet?.connected && !wallet?.account?.address}
          onClick={showModal}
        >
          Treasury Deposit
        </span>
      ) : (
        <span className="me-2" onClick={TreasuryInitiate}>
          Create Treasury
        </span>
      )}
      <Modal
        title={`${tokenName} Treasury Deposit `}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className="supply-modal"
      >
        <Form layout="vertical">
          <Form.Item>
            <Tooltip title="Total amount of assets to deposit">
              <div className="d-flex align-items-center">
                Amount <MdOutlineInfo className="ms-1" />
              </div>
            </Tooltip>
            <div className="amount-input">
              <div className="p-2 amount-info d-flex justify-content-between">
                <span>
                  ${(calculatedBalance * usdValue).toFixed(2) || "0.00"}
                </span>
                <span>
                  Wallet Balance: {tokenBalances.toFixed(4)}
                </span>
              </div>
              <div className="d-flex">
                <Input
                  placeholder="0.00"
                  lang="en"
                  className="amount-value"
                  type="number"
                  min={0}
                  max={tokenBalances}
                  value={calculatedBalance}
                  onChange={handleInputChange}
                />
                <button
                  className="max-btn"
                  type="button"
                  onClick={handleMaxClick}
                >
                  MAX
                </button>
                <div className="mx-2 d-flex align-items-center">
                  <img
                    src={
                      tokenName === "SUI"
                        ? Sui
                        : tokenName === "WETH"
                        ? WETH
                        : USDC
                    }
                    alt=""
                    className="img-fluid me-1"
                    width={18}
                  />
                  <strong>{tokenName}</strong>
                </div>
              </div>
              <div className="mt-2 d-flex align-items-center"></div>
            </div>
          </Form.Item>
          <Button
            size="large"
            block
            disabled={calculatedBalance <= 0 || calculatedBalance > parseFloat(tokenBalances)}
            onClick={handleClick}
          >
            {calculatedBalance <= 0
              ? "Enter Amount"
              : `Deposit ${tokenName}`}
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default DepositModal;
