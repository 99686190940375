import { useWallet, ConnectModal } from "@suiet/wallet-kit";
import { useEffect, useState } from "react";
import { apiToken } from "../Apis/Apis";

function SuiWalletConnect() {
  const wallet = useWallet();
  const [showModal, setShowModal] = useState(false);

useEffect(()=>{
if(wallet?.account?.address || wallet.connected || apiToken){
    setShowModal(false)
}
},[wallet?.account?.address, wallet.connected])


  return (
    <div>
      {wallet.connected ? (
        <p>Connected to {wallet?.account?.address}</p>
      ) : (
        <span onClick={() => setShowModal(true)}>Connect Wallet</span>
      )}

      <ConnectModal open={showModal} onOpenChange={setShowModal} />
    </div>
  );
}

export default SuiWalletConnect;
