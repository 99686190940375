import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Tooltip, message } from "antd";
import { FaVault } from "react-icons/fa6";
import axios from "axios";
import { BaseUrl } from "../Common/Apis/Apis";
import toast from "react-hot-toast";
import { Transaction } from "@mysten/sui/transactions";
import { getConfig } from "../Common/Address/Address";
import { useWallet } from "@suiet/wallet-kit";

const VaultDetails = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const [depositFeeValue, setDepositFeeValue] = useState(0);
  const [depositFee, setDepositFee] = useState(0);
  const [isDepositFeeEnabled, setDepositFeeEnabled] = useState(false);
  const apiToken = sessionStorage?.getItem("token");
  const wallet = useWallet();
  const walletAddress = wallet?.account?.address;

  useEffect(() => {
    const fetchVaultDetails = async () => {
      try {
        const response = await axios.get(
          `${BaseUrl}admin/getVaultStatsDetails`,
          {
            headers: {
              accept: "application/json",
              Authorization: apiToken.replace(/^"|"$/g, ""),
            },
          }
        );
        const data = response?.data?.data[0];
        form.setFieldsValue({
          withdrawal_processsing: data.withdrawal_processsing,
          vault_capacity: data.vault_capacity,
          performance_fee: data.performance_fee,
          DepositFee: data.user_deposit_value,
        });
        setDepositFeeValue(data.user_deposit_value);
        setDepositFee(data.user_deposit_value);
        setLoading(false);
      } catch (error) {
        setOpen(false);
        setDepositFeeEnabled(false);
        console.error("Error fetching vault details", error);
        toast.error("Failed to load vault details");
        setLoading(false);
      }
    };

    if (open) {
      setLoading(true);
      fetchVaultDetails();
    }
  }, [open, form]);

  const showLoading = () => {
    setOpen(true);
    setDepositFeeEnabled(false);
  };

  const handleSave = async () => {
    try {
      // Get the latest form values
      const values = form.getFieldsValue();
      
      const withdrawal_processsing = String(values.withdrawal_processsing);
      const vault_capacity = String(values.vault_capacity);
      const performance_fee = String(values.performance_fee);
      const user_deposit_value = isDepositFeeEnabled
        ? String(depositFeeValue) // Use the updated depositFeeValue from state
        : null;
  
      if (isDepositFeeEnabled) {
        const txb = new Transaction();
        const config = await getConfig();
        const Owner = process.env.REACT_APP_MODE;
        const FeeConfig = Owner === "DEV" 
          ? process.env.REACT_APP_DEV_FEE_CONFIG 
          : process.env.REACT_APP_PROD_FEE_CONFIG;
        
        try {
          txb.moveCall({
            target: `${config.FairmilePackage}::${config.FairmileModule}::set_fee_percentage`,
            arguments: [txb.object(FeeConfig), txb.pure.u64(user_deposit_value)],
          });
  
          try {
            const result = await wallet?.signAndExecuteTransactionBlock({
              transactionBlock: txb,
            });
            toast.success("Transaction successful");
          } catch (error) {
            toast.error(
              error.message === "[WALLET.SIGN_TX_ERROR] Rejected from user"
                ? "Rejected by user"
                : error.message || "Transaction failed"
            );
            console.error("Error executing transaction:", error);
            return; // Stop the execution if the transaction fails
          }
        } catch (error) {
          toast.error(
            error?.message === "[WALLET.SIGN_TX_ERROR] Rejected by user"
              ? "Rejected by user"
              : error?.message || "Transaction failed"
          );
          console.error("Error executing transaction:", error);
          return; // Stop the execution if there's an error
        }
      }
  
      // Proceed with saving vault details to API only after transaction
      try {
        const response = await axios.post(
          `${BaseUrl}admin/saveVaultStatsDetails`,
          {
            withdrawal_processsing,
            vault_capacity,
            performance_fee,
            user_deposit_value, // Send the updated deposit fee value to the API
          },
          {
            headers: {
              Authorization: apiToken.replace(/^"|"$/g, ""),
            },
          }
        );
        setOpen(false);
        setDepositFeeEnabled(false);
        toast.success("Vault details saved successfully");
      } catch (error) {
        console.error("Error saving vault details", error);
        message.error("Failed to save vault details");
      }
    } catch (error) {
      console.error("Error during saving:", error);
    }
  };
  
  const handleCheckboxChange = (e) => {
    setDepositFeeEnabled(e.target.checked);
  };

  const handleDepositFeeChange = (e) => {
    setDepositFeeValue(e.target.value); // Update the deposit fee value dynamically
  };

  const calculatePercentage = (value) => {
    return (value / 10000) * 100;
  };

  return (
    <>
      <Tooltip title={"Update Vault Detail Data"}>
        <FaVault style={{ cursor: "pointer" }} onClick={showLoading} />
      </Tooltip>
      <Modal
        title={"Vault Details"}
        footer={null}
        loading={loading}
        open={open}
        onCancel={() => setOpen(false)}
      >
        <Form layout="vertical" form={form} onFinish={handleSave}>
          <Form.Item
            label="Withdrawal Processing Time"
            name="withdrawal_processsing"
            rules={[
              {
                required: true,
                message: "Please enter withdrawal processing time",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Vault Capacity"
            name="vault_capacity"
            rules={[{ required: true, message: "Please enter vault capacity" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Performance Fee"
            name="performance_fee"
            rules={[
              { required: true, message: "Please enter performance fee" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={
              <>
                Deposit Fee{" "}
                <input
                  type="checkbox"
                  className="mx-2"
                  onChange={handleCheckboxChange}
                />
                <span className="text-success">
                  {" "}
                  {calculatePercentage(depositFee)}%
                </span>
              </>
            }
            name="DepositFee"
            rules={[
              {
                required: isDepositFeeEnabled, // Only required if the input is enabled
                message: "Please enter user deposit value",
              },
            ]}
          >
            <Tooltip
              title={`${calculatePercentage(depositFeeValue)}%`} // Display percentage in tooltip
            >
              <Input
                disabled={!isDepositFeeEnabled}
                value={depositFeeValue}
                onChange={handleDepositFeeChange} // Update on input change
              />
            </Tooltip>
          </Form.Item>
          <Form.Item className="text-center">
            <Button
              type="default"
              htmlType="submit"
              className="supplyBtn w-100"
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default VaultDetails;
