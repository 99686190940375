import React, { useEffect, useState } from "react";
import { Form, Modal, Input, Tooltip, Button, Slider } from "antd";
import { MdOutlineInfo } from "react-icons/md";
import "./WithdrawModal.css";
import Sui from "../../../Assets/Svg/Sui.svg";
import WETH from "../../../Assets/Svg/Weth.svg";
import USDC from "../../../Assets/Svg/Usdc.svg";
import { useWallet } from "@suiet/wallet-kit";
import { WithdrawTreasury } from "./WithdrawTreasury";
import toast from "react-hot-toast";
import { getConfig } from "../../Common/Address/Address";

const WithdrawTreasuryModal = ({
  usdValue,
  tokenName,
  treasureSuiBalances,
  treasureUsdcBalances,
  treasureUsdcNative,
  treasureWethBalances,
  CoinObjectId,
  fetchTreasureWethBalance,
  fetchTreasureUsdcBalance,
  fetchTreasureBalance,
  handleReload,
  fetchBalances,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const balance =
    tokenName === "SUI"
      ? treasureSuiBalances
      : tokenName === "wUSDC"
      ? treasureUsdcBalances
      : tokenName === "USDC"
      ? treasureUsdcNative
      : treasureWethBalances;
  const wallet = useWallet();

  const handleClick = async () => {
    if (!wallet) {
      toast.error("Please connect your Sui wallet first.");
      return;
    }
    if (amount > balance) {
      toast.error("Entered Value is more then Wallet Balance.");
      return;
    }
    try {
      const result = await WithdrawTreasury(
        CoinObjectId,
        wallet,
        amount,
        tokenName,
        fetchTreasureWethBalance,
        fetchTreasureUsdcBalance,
        fetchTreasureBalance
      );
      handleCancel();
      handleReload();
      fetchBalances();
    } catch (error) {
      console.error("Transaction failed:", error);
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
    setAmount("");
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  //const handleSliderChange = (value) => {
  //setSliderValue(value);
  //const newAmount = (value / balance) * 100;
  //setAmount(newAmount.toFixed(4));
  //};

  const handleMaxClick = () => {
    setAmount(balance);
    //setSliderValue(100);
  };

  const handleInputChange = (e) => {
    let value = e.target.value;
    setAmount(value);
    //setSliderValue((value / balance) * 100);
  };

  const marks = {
    0: "0",
    25: "25",
    50: "50",
    75: "75",
    100: "100",
  };
  const [config, setConfig] = useState(null);

  const loadConfig = async () => {
    let detail = await getConfig();
    setConfig(detail);
  };
  useEffect(() => {
    loadConfig();
  }, []);
  return (
    <>
      {(tokenName === "SUI" && config?.suiTreasury) ||
      (tokenName === "wUSDC" && config?.usdcNativeTreasury) ||
      (tokenName === "USDC" && config?.usdcNativeTreasury) ||
      (tokenName === "WETH" && config?.wethTreasury) ? (
        <span className="me-2" onClick={showModal}>
          Treasury Withdraw
        </span>
      ) : (
        ""
      )}
      <Modal
        title={`${tokenName} Treasury Withdrawal`}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        className="supply-modal"
      >
        <Form layout="vertical">
          <Form.Item>
            <Tooltip title="Total amount of assets to withdraw">
              <div className="d-flex align-items-center">
                Amount <MdOutlineInfo className="ms-1" />
              </div>
            </Tooltip>
            <div className="amount-input">
              <div className="p-2 amount-info d-flex justify-content-between">
                <span>${(amount * usdValue).toFixed(2) || "0.00"}</span>
                <span>
                  Treasury {tokenName} Balance: {balance.toFixed(4)}
                </span>
              </div>
              <div className="d-flex">
                <Input
                  placeholder="0.00"
                  className="amount-value"
                  type="number"
                  min={0}
                  max={balance}
                  value={amount}
                  onChange={handleInputChange}
                />
                <button className="max-btn" onClick={handleMaxClick}>
                  MAX
                </button>
                <div className="mx-2 d-flex align-items-center">
                  <img
                    src={
                      tokenName === "SUI"
                        ? Sui
                        : tokenName === "WETH"
                        ? WETH
                        : USDC
                    }
                    alt=""
                    className="img-fluid me-1"
                    width={18}
                  />
                  <strong>{tokenName}</strong>
                </div>
              </div>
              <div className="mt-2 d-flex align-items-center"></div>
            </div>
          </Form.Item>
          {/*<Slider
            marks={marks}
            value={sliderValue}
            onChange={handleSliderChange}
          />*/}
          <Button
            size="large"
            block
            className="supplyBtn"
            onClick={handleClick}
            disabled={amount <= 0 || amount > parseFloat(balance)}
          >
            {amount <= 0 ? "Enter Amount" : `Withdraw ${tokenName}`}
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default WithdrawTreasuryModal;
