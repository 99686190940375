import React, { useState } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { GiCheckMark } from "react-icons/gi";
import { FaCopy } from "react-icons/fa6";
import OTPInput from "react-otp-input";
import { TbReload } from "react-icons/tb";
import { SiGoogleauthenticator } from "react-icons/si";
import { Button, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import authenticator from "../../../Assets/Svg/google-authenticator.svg";
import Gauthenticator from "../../../Assets/Images/Google_Authenticator_for_Android_icon.png";
import QRCode from "qrcode.react";
import { BaseUrl } from "../../Common/Apis/Apis";
import { useNavigate } from 'react-router-dom';

function Authenticator() {
  const [show, setShow] = useState(false);
  const [qrGenerated, setQrGenerated] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [otp, setOtp] = useState("");
  const [qrData, setQrData] = useState({
    secret: null,
    otpauthUrl: '',
    qrEnabled: false
  });

  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const copyToClipboard = () => {
    const input = document.getElementById("secretInput");
    input.select();
    document.execCommand("copy");
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const qrGenerate = async () => {
    setLoading(true);
    const apiToken = sessionStorage?.getItem("token");
    try {
      const response = await axios.get(BaseUrl + "admin/generate_qr", {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': apiToken?.replace(/^"|"$/g, "") || "",
        },
      });
      
      const { secret, otpauthUrl, qrEnabled } = response?.data?.data || {};
      setQrData({ secret, otpauthUrl, qrEnabled });
      setQrGenerated(secret);
    } catch (error) {
      toast.error(error?.response?.data?.error || "Failed to generate QR code");
    } finally {
      setLoading(false);
    }
  };

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
  };

  const verifyAuth = async (e) => {
    e.preventDefault();
    try {
      const token = sessionStorage?.getItem("token");
      if (!token) throw new Error("No authentication token found");
      const response = await axios.post(
        `${BaseUrl}admin/verify_qr`,
        { otp },
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token?.replace(/^"|"$/g, ""),
          },
        }
      );

      const { twoFactorEnabled } = response?.data?.data || {};
      
      if (twoFactorEnabled) {
        toast.success(response?.data?.message || "Two Factor Authentication Enabled! Please login again.");
        handleClose();
        
        sessionStorage.removeItem("token");
        localStorage.removeItem("enable_auth");
        
        setTimeout(() => {
          navigate('/admin/login');
        }, 2000);
      } else {
        toast.error("Failed to enable 2FA. Please try again.");
      }
      
    } catch (error) {
      toast.error(error?.response?.data?.error || "Error verifying OTP");
      setOtp("");
    }
  };
  return (
    <>
      <span onClick={handleShow} style={{ cursor: "pointer" }}>
        <SiGoogleauthenticator className="text-primary me-2"/> Authenticator
      </span>

      <Modal
        show={show}
        onHide={handleClose}
        className="authModal g_authentication"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="border-bottom-0">
          <span className={`f-${currentStep === 1 ? 40 : 22}`}>
            {currentStep === 1 && "Google Authentication"}
            {currentStep === 2 &&
              "Scan this QR code in the Google Authenticator app"}
            {currentStep === 3 && "Google Authentication Code"}
          </span>
          {currentStep === 1 && (
            <img
              src={authenticator}
              alt=""
              width={25}
              className="ms-3 align-self-center"
            />
          )}
        </Modal.Header>
        <Modal.Body>
          <div>
            {currentStep === 1 && (
              <div className="text-center">
                <p className="mb-3 f-20 text-start text-muted">
                  Please download and install the Google Authenticator app on
                  your phone
                </p>
                <div className="mt-5 d-flex">
                  <div
                    className="p-4 rounded col-12 col-md-8 d-flex flex-column justify-content-center bg-light"
                    style={{ lineHeight: "23px" }}
                  >
                    <ol className="text-start">
                      <li>
                        Download and install the Google Authenticator app.
                      </li>
                      <li>Open the app and click "Begin setup" or "+" sign.</li>
                      <li>
                        Click "Next" and scan the QR code or manually enter the
                        provided code.
                      </li>
                      <li>
                        The app will automatically add the account and generate
                        OTPs.
                      </li>
                    </ol>
                  </div>
                  <div className="col-12 col-md-4 position-relative d-flex align-items-center justify-content-center">
                    <img
                      src={Gauthenticator}
                      alt=""
                      className="img-fluid"
                      width={200}
                    />
                  </div>
                </div>
              </div>
            )}
            {currentStep === 2 && (
              <div className="text-center">
                {loading ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  <>
                    {!qrData?.otpauthUrl ? (
                      <TbReload
                        className="p-3 border rounded-circle bg-light text-dark"
                        onClick={qrGenerate}
                        style={{
                          cursor: "pointer",
                          marginTop: "10px",
                          fontSize: "70px",
                        }}
                      />
                    ) : (
                      <div>
                        <QRCode
                          value={qrData?.otpauthUrl}
                          style={{ height: "136px", width: "136px" }}
                        />
                        <TbReload
                          onClick={qrGenerate}
                          style={{
                            cursor: "pointer",
                            marginTop: "10px",
                            color: "#00ADEF",
                          }}
                        />
                      </div>
                    )}
                  </>
                )}
                
                {qrData?.secret?.base32 && (
                  <div className="mt-4">
                    <div className="mb-3 input-group">
                      <input
                        id="secretInput"
                        type="text"
                        className="form-control"
                        value={qrData?.secret?.base32}
                        readOnly
                      />
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        onClick={copyToClipboard}
                      >
                        {copied ? (
                          <GiCheckMark style={{ color: "#00ADEF" }} />
                        ) : (
                          <FaCopy style={{ color: "#00ADEF" }} />
                        )}
                      </button>
                    </div>
                    
                    <span className="f-14 text-muted text-start d-block mt-2">
                      Please save this secret key. It will allow you to recover your
                      Google Authenticator in case of phone loss.
                    </span>
                  </div>
                )}
              </div>
            )}
            {currentStep === 3 && (
              <div className="text-center">
                <p className="mb-3 f-20 text-start text-muted">
                  Enter the OTP received in your Google Authenticator app
                </p>
                <div className="mt-4 d-flex justify-content-center auth-passwords">
                  <OTPInput
                    value={otp}
                    onChange={(otp) => setOtp(otp)}
                    numInputs={6}
                    renderInput={(props, index) => (
                      <input {...props} key={index} />
                    )}
                  />
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-top-0">
          {currentStep > 1 && (
            <Button onClick={handlePrevious} className="w-25 supplyBtn">
              Previous
            </Button>
          )}
          {currentStep < 3 && (
            <Button onClick={handleNext} className="w-25 supplyBtn">
              Next
            </Button>
          )}
          {currentStep === 3 && (
            <Button onClick={verifyAuth} className="w-25 supplyBtn">
              Submit
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Authenticator;
